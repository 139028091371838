import Vue from "vue"
import Vuetify from "vuetify/lib"
import ru from "vuetify/es5/locale/ru"

Vue.use(Vuetify)

export default new Vuetify({
  lang: {
    locales: { ru },
    current: "ru",
  },
  theme: {
    dark: false,
    themes: {
      light: {
        primary: "#337ab7",
        accent: "#f3377a",
        secondary: "#3a4750",
        success: "#4CAF50",
        info: "#2196F3",
        warning: "#FB8C00",
        error: "#FF5252",
      },
    },
  },
})
