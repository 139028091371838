<template>
  <v-dialog :value="dialog" width="500">
    <v-card>
      <v-card-title class="error--text">
        <v-icon color="error" class="mr-2">
          mdi-alert-outline
        </v-icon>
        Ошибка
      </v-card-title>
      <v-card-text> {{ error }} </v-card-text>
      <v-card-actions class="justify-center" v-if="!hideCloseButton">
        <v-btn color="primary" depressed @click="close">
          ОК
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      error: undefined,
      hideCloseButton: false,
      timeout: undefined,
    }
  },

  methods: {
    open(error, hideCloseButton = false) {
      this.error = error
      this.hideCloseButton = hideCloseButton
      this.dialog = true
      if (hideCloseButton) {
        this.timeout = setTimeout(() => {
          this.close()
        }, 2500)
      }
    },
    close() {
      if (this.timeout) {
        clearTimeout(this.timeout)
      }
      this.dialog = false
    },
  },
}
</script>
