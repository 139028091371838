<template>
  <v-app>
    <Loader v-if="loading" />

    <div class="fullscreen-center" v-if="paid">
      <div class="text-center">
        <SuccessAnimation />
        <div class="success--text font-weight-bold mt-4">
          <div class="text-center">{{ user.names.lastFirst }},</div>
          Оплата прошла успешно!
        </div>
      </div>
    </div>

    <div class="fullscreen-center" v-else-if="cart.length === 0">
      <div class="text-center">
        <v-icon color="grey" style="font-size: 60px;">mdi-barcode-scan</v-icon>
        <div class="grey--text mt-2">
          просканируйте товар
        </div>
      </div>
    </div>

    <ErrorMessageDialog ref="ErrorMessageDialog" />

    <v-main v-if="cart.length > 0">
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">
                Товар
              </th>
              <th class="text-left">
                Стоимость
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in cart" :key="index">
              <td>{{ item.name }}</td>
              <td width="200">{{ item.price }} руб.</td>
              <td width="100">
                <v-btn icon color="red" @click="removeItem(item, index)">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </td>
            </tr>
            <tr>
              <td></td>
              <td class="font-weight-bold">Итого: {{ total }} руб.</td>
              <td></td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-main>
  </v-app>
</template>

<script>
import TSD from "@/mixins/TSD"
import SuccessAnimation from "@/components/SuccessAnimation"
import Loader from "@/components/Loader"
import ErrorMessageDialog from "@/components/ErrorMessageDialog"

const API_URL = "products"

export default {
  name: "App",

  mixins: [TSD],

  components: { SuccessAnimation, Loader, ErrorMessageDialog },

  data() {
    return {
      products: {},
      cart: [],
      loading: false,
      paid: false,
      user: null,
    }
  },

  mounted() {
    this.$window.document.addEventListener(
      "visibilitychange",
      this.refreshProducts,
      false,
    )
    this.refreshProducts()
  },

  destroyed() {
    this.$window.onfocus = null
  },

  methods: {
    refreshProducts() {
      //console.log(this.$window.document.hidden)
      if (this.$window.document.hidden) {
        return
      }
      this.$http.get(API_URL).then(r => {
        r.data.data.every(item => (this.products[item.barcode] = item))
      })
    },
    removeItem(item, index) {
      this.cart.splice(index, 1)
    },

    getEmailId(barcode) {
      // проверка и получение клиента из баркода клиента
      if (barcode.length < 3) {
        return false
      }
      if (barcode.slice(-1) !== "*") {
        return false
      }
      if (barcode.slice(0, 1) !== "*") {
        return false
      }
      return barcode.slice(1, -1)
    },

    onScan(barcode) {
      if (this.loading) {
        return
      }
      this.$refs.ErrorMessageDialog.close()
      if (barcode.length > 5 && barcode.slice(0, 1) !== "*") {
        // product scan
        const product = this.products[barcode]
        if (product) {
          this.cart.push(product)
        } else {
          this.$refs.ErrorMessageDialog.open(
            "Товар не найден или штрих код не читается",
            true,
          )
        }
        return
      }

      if (!this.cart.length) {
        return
      }

      this.pay(barcode)
    },

    pay(barcode) {
      const emailId = this.getEmailId(barcode)
      console.log("EMAIL", emailId)
      if (emailId === false) {
        return this.$refs.ErrorMessageDialog.open("Пользователь не найден")
      }
      this.loading = true
      this.$http
        .post(API_URL + "/pay", {
          cart: this.cart,
          email_id: emailId,
        })
        .then(r => {
          this.paid = true
          this.user = r.data
          this.cart = []
        })
        .catch(() =>
          this.$refs.ErrorMessageDialog.open("Ошибка в процессе оплаты"),
        )
        .finally(() => (this.loading = false))
    },

    finish() {
      this.$refs.ErrorMessageDialog.close()
      this.cart = []
    },
  },

  watch: {
    paid(paid) {
      if (paid) {
        setTimeout(() => (this.paid = false), 5000)
      }
    },
  },

  computed: {
    total() {
      return this.cart.reduce((total, item) => total + item.price, 0)
    },
  },
}
</script>

<style lang="scss">
html,
body {
  overflow: auto !important;
}
.fullscreen-center {
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
}
</style>
