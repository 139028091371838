import Vue from "vue"
import App from "./App.vue"
import "./registerServiceWorker"
import store from "./store"
import http from "@/plugins/http"
import vuetify from "./plugins/vuetify"
import "roboto-fontface/css/roboto/roboto-fontface.css"
import "@mdi/font/css/materialdesignicons.css"
import './registerServiceWorker'
require("@/scss/style.scss")

Vue.config.productionTip = false
Vue.prototype.$http = http
Vue.prototype.$window = window;
new Vue({
  store,
  vuetify,
  render: h => h(App),
}).$mount("#app")
