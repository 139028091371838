export default {
  data() {
    return {
      tsd: { barcode: "", lastTime: 0 },
    }
  },

  methods: {
    isFunction(functionToCheck) {
      return (
        functionToCheck &&
        {}.toString.call(functionToCheck) === "[object Function]"
      )
    },
    onTSDKeyDown(e) {
      const newTime = new Date().getTime()
      // Иногда сканер считывает * как ; – это фикс
      const key = e.key === ";" ? "*" : e.key

      if (newTime - this.tsd.lastTime > 100) {
        this.tsd.barcode = ""
      }
      this.tsd.lastTime = newTime
      if ((key >= 0 && key <= 9) || key === '*') {
        return (this.tsd.barcode += key)
      }
      if (key !== "Enter") {
        return
      }
      console.log(this.tsd.barcode)
      if (this.tsd.barcode.length < 1) {
        console.warn("broken barcode")
        return
      }
      this.$emit("scan", this.tsd.barcode)
      if (this.isFunction(this.onScan)) {
        this.onScan(this.tsd.barcode)
      }
      this.tsd.barcode = ""
    },
  },
  mounted() {
    document.addEventListener("keydown", this.onTSDKeyDown)
    console.log("scanner on")
  },
  destroyed() {
    document.removeEventListener("keydown", this.onTSDKeyDown)
    console.log("scanner off")
  },
}
